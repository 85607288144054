import { Location } from "@angular/common";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, of, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { LoaderService } from "src/app/shared/services/loader.service";
import { PermissionsService } from "src/app/shared/services/permissions.service";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private router: Router,
    private permission: PermissionsService,
    private spinner: NgxSpinnerService,
    private loading: LoaderService,
    private location: Location
  ) {}

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Sorry your session is expired please login again",
        showConfirmButton: false,
        timer: 3000,
      });
      this.permission.user = null;
      this.permission.permissionGroups$ = [];
      localStorage.clear();
      this.router.navigate(["account/user/login"]);
      return of(err.message);
    }
    if (err.status === 403) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: err?.error?.message,
        showConfirmButton: false,
        timer: 3000,
      });
      // this.permission.user = null;
      // this.permission.permissionGroups$ = [];
      // localStorage.clear();
      // this.router.navigate(["account/user/login"]);
      // this.location.back();
      return of(err.message);
    }
    if (err.status === 500) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Server error, please try again later.!",
        showConfirmButton: false,
        timer: 3000,
      });
      return of(err.message);
    }
    if (err.status === 504) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "504 Gateway Time-out!",
        showConfirmButton: false,
        timer: 2000,
      });
      return of(err.message);
    }
    if (err.status === 0) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Server error, please try again later.!",
        showConfirmButton: false,
        timer: 3000,
      });
      return of(err.message);
    }
    return throwError(err);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authService = this.injector.get(PermissionsService);
    this.loading.setMLoader(true);
    let tokenizedReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${authService ? authService.getToken() : ""}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
    return next.handle(tokenizedReq).pipe(
      finalize(() => {
        this.spinner.hide();
      }),
      catchError((x) => this.handleAuthError(x))
    );
    // throw new Error('Method not implemented.');
  }
}
