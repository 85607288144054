import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SocketioService {
  private messageEvent = new Subject<{}>();
  private tweetEvent = new Subject<{}>();
  private AkomaChatEvent = new Subject<{}>();

  socket;
  isAkomaEvent: any;
  constructor() {}

  // setupSocketConnection(room) {
  //   console.log("socket angulARS server", room);

  //   this.socket = io(environment.SOCKET_ENDPOINT);
  //   // this.socket.emit("create", room);

  //   // this.socket.emit(room, "Hello there from Angular.");
  //   this.socket.on(room, (data: string) => {
  //     console.log(data);
  //   });
  // }

  setupSocketConnection() {
    console.log("socket angulARS server");

    // this.socket.emit("create", room);

    this.socket.onAny((event, ...args) => {
      console.log('HHHHSSSEE', event, ...args);
      if(event.hasOwnProperty('tweetsData')) {
        this.setTweet(event.tweets);
      }
      else if (args.length > 0) {
        args.map((arg)=>{
          if(arg === 'pannelChatEvent') this.isAkomaEvent = true;
          else this.isAkomaEvent = false;
        });
        if(this.isAkomaEvent) this.setAkomaChatMessage(event)
        else this.setMessage(event, ...args);
      }
      else this.setMessage(event, ...args);
    });
    
    // this.socket.emit(room, "Hello there from Angular.");
    // this.socket.on("*", (event, data) => {
    //   console.log(event);
    //   console.log(data);
    // });
    // this.socket.on("global", (event, data) => {
    //   console.log(event);
    //   console.log(data);
    // });
  }

  setMessage(event, ...args) {
    console.log("Socket Message Event",args[0]);
    this.messageEvent.next(args[0]);
  }

  getMessage() {
    return this.messageEvent.asObservable();
  }

  setTweet(event) {
    console.log("Socket Tweet Event",event);
    this.tweetEvent.next(event);
  }

  getTweet() {
    return this.tweetEvent.asObservable();
  }

  setAkomaChatMessage(event) {
    console.log("Socket Akoma Chat Event",event);
    this.AkomaChatEvent.next(event);
  }

  getAkomaChatMessage() {
    return this.AkomaChatEvent.asObservable();
  }

  listenToRoom(room) {
    this.socket.onAny((event, ...args) => {
      console.log(`got ${event}`);
    });
  }

  createRoom(room) {
    this.socket.emit("create", room);
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}
