import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { LoaderService } from "src/app/shared/services/loader.service";
import { SocketioService } from "src/app/shared/services/socket.service";
import { HttpService } from "./shared/services/http.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private router: ActivatedRoute,
    private loader: LoaderService,
    private spinner: NgxSpinnerService,
    private socketService: SocketioService,
    public readonly http: HttpService
  ) {
    // --------------- Disable element's and right click
    // document.addEventListener("contextmenu", function (e) {
    //   e.preventDefault();
    // });
  }
  ngOnInit() {
    this.loader.getMLoader().subscribe(this.loaderEvent.bind(this));
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    this.socketService.setupSocketConnection();
    this.socketService
      .getMessage()
      .subscribe(this.messageEventEmitter.bind(this));
  }

  messageEventEmitter(event) {
    // this.spinner.show();
  }

  loaderEvent(data) {
    if (data) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
  }
  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(e: KeyboardEvent) {
    if (e.key === "F12") {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === "I") {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === "C") {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === "J") {
      return false;
    }
    if (e.ctrlKey && e.key == "U") {
      return false;
    }
    return true;
  }
}
