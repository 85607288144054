import { HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import { HttpService } from "src/app/shared/services/http.service";
import { LogoutService } from "src/app/shared/services/logout.service";
import { PermissionsService } from "src/app/shared/services/permissions.service";
import { UtilitiesService } from "src/app/shared/services/utilities.service";
import Swal from "sweetalert2";

interface BillingReportsModel {
  id: number;
  name: string;
  url: string;
  img: string;
}
@Component({
  selector: "app-rightsidebar",
  templateUrl: "./rightsidebar.component.html",
  styleUrls: ["./rightsidebar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})

/**
 * Rightsidebar component
 */
export class RightsidebarComponent implements OnInit {
  password: any = "";
  Cpassword: string = "";
  old_Password: string = "";

  pathName: any = "";
  billingUrl: string = "";
  administratorUrl: string = "";
  cprReportUrl:string = "";
  profileUrl: string = "";
  selectedOrganizationID: string = "";
  sideBar = true;
  currentUser: any = null;
  UserDetail: any = null;

  show_button: Boolean = false;
  show_eye: Boolean = false;
  show_button1: Boolean = false;
  show_eye1: Boolean = false;
  show_button2: Boolean = false;
  show_eye2: Boolean = false;
  @ViewChild("closeBtn") closeBtn: ElementRef;

  constructor(
    public http: HttpService,
    private logoutService: LogoutService,
    public permissionService: PermissionsService,
    public readonly UtilitiesService: UtilitiesService,
    public router: Router
  ) {}

  ngOnInit() {
    this.pathName = window.location.pathname;
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.initStateChanges(this.currentUser);
    this.UtilitiesService.getUserDetailGlobal(this.currentUser?.user.id);
  }

  initStateChanges(currentUser) {
    if (!!!this.permissionService.permissionGroups$.length) {
      if (currentUser != null) {
        currentUser.user.user_roles.permissions.forEach((permissionsGroup) => {
          this.permissionService.permissionGroups$.push(permissionsGroup.name);
        });

        this.permissionService.permissionGroups$ = [
          ...new Set(this.permissionService.permissionGroups$),
        ];
      } else {
        this.logoutService.logout();
      }
    }

    this.compareSideBar(this.permissionService.permissionGroups$);
    this.links.sort((a, b) => a?.id - b?.id);
    this.cdrUrl.sort((a, b) => a?.id - b?.id);
    this.administrationList.sort((a, b) => a?.id - b?.id);
    this.profileList.sort((a, b) => a?.id - b?.id);
    this.BillingReports.sort((a, b) => a?.id - b?.id);
    this.cprReportList.sort((a, b) => a?.id - b?.id);
  }

  links: Array<BillingReportsModel> = [
    {
      id: 0,
      name: "Dashboard",
      url: "/dashboard",
      img: "./assets/images/svgIcons/dashboard.svg",
    },
  ];

  cdrUrl: Array<BillingReportsModel> = [];
  BillingReports: Array<any> = [];
  administrationList: Array<any> = [];
  profileList: Array<any> =[];
  cprReportList: Array<any>= [];
  compareSideBar(permissionArray) {
    if (permissionArray.length) {
      permissionArray.forEach((e) => {
        if (e == "Service Providers") {
          const obj = {
            id: 1,
            name: "Service Providers",
            url: "/dashboard/service-provider",
            img: "./assets/images/svgIcons/service-providers.svg",
          };
          this.links.push(obj);
          this.links = [...new Set(this.links)];
        }
        if (e == "Organizations") {
          const obj = {
            id: 2,
            name: "Organizations",
            url: "/dashboard/organization",
            img: "./assets/images/svgIcons/organizations.svg",
          };

          this.links.push(obj);
          this.links = [...new Set(this.links)];
        }

        if (e == "Voip Users") {
          const obj = {
            id: 3,
            name: "Users Voip",
            url: "/dashboard/voip",
            img: "./assets/images/svgIcons/user-voip.svg",
          };
          this.links.push(obj);
          this.links = [...new Set(this.links)];
        }
        if (e == "Extensions") {
          const obj = {
            id: 4,
            name: "Extensions",
            url: "/dashboard/extension",
            img: "./assets/images/svgIcons/extentions.svg",
          };
          this.links.push(obj);
          this.links = [...new Set(this.links)];
        }
        if (e == "Dids") {
          const obj = {
            id: 5,
            name: "DID's",
            url: "/dashboard/dids",
            img: "./assets/images/svgIcons/dids.svg",
          };
          this.links.push(obj);
          this.links = [...new Set(this.links)];
        }
        if (e == "Cdrs") {
          const obj = {
            id: 6,
            name: "CDR",
            url: "/dashboard/cdr",
            img: "./assets/images/svgIcons/cdr.svg",
          };
          this.cdrUrl.push(obj);
          this.cdrUrl = [...new Set(this.cdrUrl)];
        }

        if (e == "CPRs") {
          if (this.permissionService.isSuperAdmin()) {
            return;
          } else {
            const obj = {
              id: 7,
              name: "CPR",
              url: "/dashboard/cpr",
              img: "./assets/images/svgIcons/cpr.svg",
            };
            this.cdrUrl.push(obj);
            this.cdrUrl = [...new Set(this.cdrUrl)];
          }
        }
        if (e == "Monitor Panel") {
          if (this.permissionService.isSuperAdmin()) {
            return;
          } else {
            const obj = {
              id: 8,
              name: "Monitor Panel",
              url: "/dashboard/monitor-panel",
              img: "./assets/images/svgIcons/cdr.svg",
            };
            this.cdrUrl.push(obj);
            this.cdrUrl = [...new Set(this.cdrUrl)];
          }
        }
        if (e == "Administration") {
          let array: Array<any> = [];
          if (this.permissionService.getClaims().length) {
            this.permissionService.getClaims().forEach((e) => {
              if (e == "Roles") {
                array.push({
                  id: 0,
                  name: "Role",
                  url: "/dashboard/admin/roles",
                  img: "",
                });
              }
              if (e == "Users") {
                array.push({
                  id: 1,
                  name: "Users",
                  url: "/dashboard/admin/users",
                  img: "",
                });
              }
              if (e == "Audit Logs") {
                array.push({
                  id: 2,
                  name: "Audit Log",
                  url: "/dashboard/admin/auditlogs",
                  img: "",
                });
              }
              if (e == "Activity Logs") {
                array.push({
                  id: 3,
                  name: "Activity Log",
                  url: "/dashboard/admin/activityLogs",
                  img: "",
                });
              }

              array.sort((a, b) => a?.id - b?.id);
              return;
            });
          }
          this.administrationList = [...new Set(array)];
        }

        if (e == "Administration") {
          let array: Array<any> = [];
          if (this.permissionService.getClaims().length) {
            this.permissionService.getClaims().forEach((e) => {
              if (e == "Users") {
                array.push({
                  id: 11,
                  name: "Profile",
                  url: "/dashboard/profile",
                  img: "",
                });
              }
              // if (e == "Roles") {
              //   array.push({
              //     id: 12,
              //     name: "Log Out",
              //     url: "/account/user/login",
              //     img: "",
              //   });
              // }
              array.sort((a, b) => a?.id - b?.id);
              return;
            });
          }
          this.profileList = [...new Set(array)];
        }

        if (e == "CPRs") {
          let array: Array<any> = [];
          if (this.permissionService.getClaims().length) {
            this.permissionService.getClaims().forEach((e) => {
              if (e == "CPRs") {
                array.push({
                  id: 11,
                  name: "CPR Report",
                  url:"/dashboard/cpr-reports",
                  img: "",
                });
              }
              array.sort((a, b) => a?.id - b?.id);
              return;
            });
          }
          this.cprReportList = [...new Set(array)];
        }

        if (e == "Billing Reports") {
          let arr: Array<any> = [];
          if (this.permissionService.getClaims().length) {
            this.permissionService.getClaims().forEach((e) => {
              if (this.permissionService.isSuperAdmin()) {
                if (e == "Org. Billing Reports") {
                  arr.push({
                    id: 0,
                    name: "Org. Billing Reports",
                    url: "/dashboard/billing-reports/organizational-list",
                    img: "",
                  });
                }
                if (e == "Prov. Billing Reports") {
                  arr.push({
                    id: 1,
                    name: "Prov. Billing Reports",
                    url: "/dashboard/billing-reports/provisional-list",
                    img: "",
                  });
                }
              }
              if (this.permissionService.isServiceProvider()) {
                if (e == "Org. Billing Reports") {
                  arr.push({
                    id: 0,
                    name: "Org. Billing Reports",
                    url: "/dashboard/billing-reports/organizational-list",
                    img: "",
                  });
                }
                if (e == "My Billing Reports") {
                  arr.push({
                    id: 2,
                    name: "My Billing Reports",
                    url: "/dashboard/billing-reports/my-reports",
                    img: "",
                  });
                }
              }
              if (this.permissionService.isOrganization()) {
                if (e == "My Billing Reports") {
                  arr.push({
                    id: 2,
                    name: "My Billing Reports",
                    url: "/dashboard/billing-reports/my-reports",
                    img: "",
                  });
                }
              }

              arr.sort((a, b) => a?.id - b?.id);
              return;
            });
          }
          this.BillingReports = [...new Set(arr)];
        }
      });
    }
  }
  removeUrl() {
    this.billingUrl = null;
    this.administratorUrl = null;
    this.cprReportUrl = null;
    this.profileUrl = null;
  }
  profile: boolean = false;
  onClickOpenProfile() {
    this.profile = !this.profile;
  }
  onClickLogout() {
    this.logoutService.logout();
  }
  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }
  showPassword1() {
    this.show_button1 = !this.show_button1;
    this.show_eye1 = !this.show_eye1;
  }
  showPassword2() {
    this.show_button2 = !this.show_button2;
    this.show_eye2 = !this.show_eye2;
  }
  changePassword() {
    let body = {
      current_password: this.old_Password,
      password: this.password ? this.password : null,
      new_confirm_password: this.Cpassword ? this.Cpassword : null,
    };
    if (this.password == this.Cpassword) {
      this.http.postRequest("/reset-password", body).subscribe(
        (response: any) => {
          if (response.success) {
            this.UtilitiesService.getUserDetailGlobal(
              this.currentUser?.user.id
            );
            Swal.fire({
              position: "center",
              icon: "success",
              title: response.message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: response.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        },
        (error: HttpErrorResponse) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: error.error.errors?.current_password[0],
            showConfirmButton: false,
            timer: 1500,
          });
        }
      );
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Enter Check your password",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }
  async onChangeOrganization(e) {
    await this.http
      .postRequest(`/org/set-default-organization`, {
        user_id: this.currentUser?.user.id,
        organization_id: e,
      })
      .subscribe(
        async (response: any) => {
          if (response.success) {
            const currentUrl = this.router.url;
            await this.router
              .navigateByUrl("/", { skipLocationChange: true })
              .then(() => {
                this.router.navigate([currentUrl]);
              });
            if (this.permissionService.isOrganization()) {
              var user: any = JSON.parse(
                localStorage.getItem("currentUser") || "{}"
              );
              user.user.default_organization.company =
                response.data?.default_organization?.company ?? "";
              localStorage.setItem("currentUser", JSON.stringify(user));
              this.permissionService.MultiOrganizationName =
                response.data?.default_organization?.company ?? "";
            }
          }
        },
        (error) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: error.error.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      );
  }
  private closeModal(): void {
    this.closeBtn.nativeElement.click();
  }
}
