import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { PermissionsService } from "src/app/shared/services/permissions.service";

@Injectable({
  providedIn: "root",
})
export class UserGuard implements CanActivate {
  constructor(
    private permissionsService: PermissionsService,
    private location: Location
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.permissionsService.loginUserDetail()) {
      this.location.back();
      return false;
    } else {
      return true;
    }
  }
}
