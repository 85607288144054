import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { HttpService } from "src/app/shared/services/http.service";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { validationEnums } from "./validationEnums";
import { LogoutService } from "src/app/shared/services/logout.service";
import { LoaderService } from "src/app/shared/services/loader.service";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private http: HttpService,
    private spinner: LoaderService,
    private logoutService: LogoutService
  ) {
    this.spinner.setMLoader(true);
  }
  user;
  returned = false;
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.http.getRequest("/checkAuth").pipe(
      map((res) => {
        this.spinner.setMLoader(false);

        let user: any = res;
        this.returned = false;
        // this.storage.setUserName(user.user.username);
        // this.storage.setPermissions(user.user.permission);
        if (res["success"] == true) {
          if (state.url.includes("/login")) {
            this.router.navigate(["/dashboard"]);
            return false;
          } else {
            if (!state.url.includes("/login")) {
              this.user = JSON.parse(localStorage.getItem("currentUser"));
              if (this.user && this.user != null) {
                this.user.user_roles.permissions.forEach((permissionGroup) => {
                  if (
                    permissionGroup.name == validationEnums.PERMISSIONGROUP_ROOT
                  ) {
                    this.returned = true;
                  }
                });

                if (!this.returned) {
                  var checkRoute;
                  if (route.routeConfig.path == "") {
                    checkRoute = "/dashboard";
                  } else {
                    checkRoute = route.routeConfig.path;
                    if (!route.routeConfig.path.startsWith("/")) {
                      checkRoute = "/" + checkRoute;
                    }
                  }

                  this.user.user_roles.permissions.forEach(
                    (permissionGroup) => {
                      if (permissionGroup.state_url == checkRoute) {
                        this.returned = true;
                      }
                    }
                  );
                }
                if (this.returned) {
                  return this.returned;
                } else {
                  this.logoutService.logout();
                  this.router.navigate(["account/user/login"]);
                  return this.returned;
                }
              } else {
                this.logoutService.logout();
                this.router.navigate(["account/user/login"]);
                return this.returned;
              }
            } else {
              this.logoutService.logout();
              this.router.navigate(["account/user/login"]);
              return false;
            }
          }
        } else if (res["statusCode"] == 401) {
          if (!state.url.includes("login")) {
            this.router.navigate(["account/user/login"]);
            return true;
          }
          return true;
        } else {
          this.router.navigate(["account/user/login"]);
          return false;
        }

        // return true;
      }),
      catchError((err) => {
        if (state.url.includes("/login")) return of(true);
        else {
          this.router.navigate(["account/user/login"]);

          return of(false);
        }
      })
    );
  }
}
