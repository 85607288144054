import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "MENUITEMS.MENU.TEXT",
    isTitle: true,
  },
  {
    id: 9,
    label: "MENUITEMS.DASHBOARD.TEXT",
    icon: "bx-calendar",
    link: "/",
    permission: [
      "root",
      "dashboard",
      "agent-dashboard",
      "client-admin-dashboard",
    ],
  },

  {
    id: 2,
    label: "MENUITEMS.DASHBOARDS.TEXT",
    icon: " fa-user",
    permission: ["root", "create-user", "list-user", "assign-role-user"],
    badge: {
      variant: "info",

      text: "MENUITEMS.DASHBOARDS.BADGE",
    },

    subItems: [
      {
        id: 3,
        label: "MENUITEMS.DASHBOARDS.LIST.CREATEUSER",
        link: "/create-user",
        parentId: 2,
        permission: ["root", "create-user"],
      },
      {
        id: 4,
        label: "MENUITEMS.DASHBOARDS.LIST.LISTUSER",
        link: "/list-user",
        parentId: 2,
        permission: ["root", "list-user"],
      },
      {
        id: 5,
        label: "MENUITEMS.DASHBOARDS.LIST.ASSIGNROLE",
        link: "user/assign/role",
        parentId: 2,
        permission: ["root", "assign-role-user"],
      },
      {
        id: 6,
        label: "MENUITEMS.DASHBOARDS.LIST.AUTH/UNAUTHUSER",
        link: "/",
        parentId: 2,
        permission: ["root", "assign-role-user"],
      },
    ],
  },

  // {
  //   id: 7,
  //   label: "MENUITEMS.MENU.TEXT",
  //   isTitle: false,
  // },
  {
    id: 8,
    label: "MENUITEMS.CLIENTMANAGEMENT.TEXT",
    icon: "fa-address-book",
    permission: ["root", "create-client", "assign-role-client", "list-client"],
    badge: {
      variant: "info",
      text: "MENUITEMS.CLIENTMANAGEMENT.BADGE",
    },
    subItems: [
      {
        id: 9,
        label: "MENUITEMS.CLIENTMANAGEMENT.LIST.CREATECLIENT",
        link: "/create-client",
        parentId: 8,
        permission: ["root", "create-client"],
      },
      {
        id: 22,
        label: "MENUITEMS.CLIENTMANAGEMENT.LIST.ASSIGNROLECLIENT",
        link: "client/assign/role",
        parentId: 8,
        permission: ["root", "assign-role-client"],
      },
      {
        id: 10,
        label: "MENUITEMS.CLIENTMANAGEMENT.LIST.ALLCLIENT",
        link: "/list-client",
        parentId: 8,
        permission: ["root", "list-client"],
      },
    ],
  },

  {
    id: 11,
    label: "MENUITEMS.AGENTMANAGEMENT.TEXT",
    icon: "fa-headset",
    permission: [
      "root",
      "create-agent",
      "assign-role-agent",
      "list-agent",
      "agents-for-clientAdmin",
    ],
    badge: {
      variant: "info",
      text: "MENUITEMS.AGENTMANAGEMENT.BADGE",
    },
    subItems: [
      {
        id: 12,
        label: "MENUITEMS.AGENTMANAGEMENT.LIST.CREATEAGENT",
        link: "/create-agent",
        parentId: 11,
        permission: ["root", "create-agent"],
      },
      {
        id: 12,
        label: "MENUITEMS.AGENTMANAGEMENT.LIST.CREATEAGENT",
        link: "/create-agent-clientadmin",
        parentId: 11,
        permission: ["create-agent-clientadmin"],
      },
      {
        id: 13,
        label: "MENUITEMS.AGENTMANAGEMENT.LIST.ASSIGNROLEAGENT",
        link: "agent/assign/role",
        parentId: 11,
        permission: ["root", "assign-role-agent"],
      },
      {
        id: 14,
        label: "MENUITEMS.AGENTMANAGEMENT.LIST.LISTAGENTS",
        link: "/list-agent",
        parentId: 11,
        permission: ["root", "list-agent"],
      },
      {
        id: 27,
        label: "MENUITEMS.AGENTMANAGEMENT.LIST.LISTAGENTSFORCLIENTADMIN",
        link: "/agents-for-clientAdmin",
        parentId: 11,
        permission: ["agents-for-clientAdmin"],
      },
    ],
  },
  {
    id: 15,
    label: "MENUITEMS.ROLE.TEXT",
    icon: "fa-users",
    permission: ["root", "create-role", "list-roles"],
    badge: {
      variant: "info",
      text: "MENUITEMS.ROLE.BADGE",
    },
    subItems: [
      {
        id: 16,
        label: "MENUITEMS.ROLE.LIST.ROLES",
        link: "/create-role",
        parentId: 15,
        permission: ["root", "create-role"],
      },
      {
        id: 17,
        label: "MENUITEMS.ROLE.LIST.LISTROLES",
        link: "/roles-list",
        parentId: 15,
        permission: ["root", "list-roles"],
      },
    ],
  },
  {
    id: 18,
    label: "MENUITEMS.PERMISSIONGROUP.TEXT",
    permission: ["root", "create-permission-group", "list-permission-group"],
    icon: "fa fa-server",
    badge: {
      variant: "info",
      text: "MENUITEMS.PERMISSIONGROUP.BADGE",
    },
    subItems: [
      {
        id: 19,
        label: "MENUITEMS.PERMISSIONGROUP.LIST.CREATEPERMISSIONGROUPS",
        link: "/create-permission-group",
        parentId: 18,
        permission: ["root", "create-permission-group"],
      },
      {
        id: 20,
        label: "MENUITEMS.PERMISSIONGROUP.LIST.LISTPERMISSIONGROUPS",
        link: "/permission-group-list",
        parentId: 18,
        permission: ["root", "list-permission-group"],
      },
    ],
  },
  {
    id: 21,
    label: "MENUITEMS.PERMISSION.TEXT",
    icon: "fa-tasks",
    permission: ["root", "create-permission", "list-permissions"],
    badge: {
      variant: "info",
      text: "MENUITEMS.PERMISSION.BADGE",
    },
    subItems: [
      {
        id: 22,
        label: "MENUITEMS.PERMISSION.LIST.PERMISSIONS",
        link: "/create-permission",
        parentId: 21,
        permission: ["root", "create-permission"],
      },
      {
        id: 23,
        label: "MENUITEMS.PERMISSION.LIST.LISTPERMISSIONS",
        link: "/permission-list",
        parentId: 21,
        permission: ["root", "list-permissions"],
      },
    ],
  },

  {
    id: 23,
    label: "MENUITEMS.SERVICEGROUP.TEXT",
    icon: "fa fa-server",
    permission: ["root", "create-service-group", "list-service-groups"],

    badge: {
      variant: "info",
      text: "MENUITEMS.SERVICEGROUP.BADGE",
    },
    subItems: [
      {
        id: 24,
        label: "MENUITEMS.SERVICEGROUP.LIST.SERVICEGROUP",
        link: "/service-group",
        parentId: 23,
        permission: ["root", "create-service-group"],
      },
      {
        id: 24,
        label: "MENUITEMS.SERVICEGROUP.LIST.LISTSERVICEGROUP",
        link: "/list-service-groups",
        parentId: 23,
        permission: ["root", "list-service-groups"],
      },
    ],
  },

  {
    id: 23,
    label: "MENUITEMS.SERVICE.TEXT",
    permission: ["root", "create-service", "list-services"],

    icon: "fa-cogs",
    badge: {
      variant: "info",
      text: "MENUITEMS.SERVICE.BADGE",
    },
    subItems: [
      {
        id: 24,
        label: "MENUITEMS.SERVICE.LIST.SERVICE",
        link: "/create-service",
        parentId: 23,
        permission: ["root", "create-service"],
      },
      {
        id: 24,
        label: "MENUITEMS.SERVICE.LIST.LISTSERVICE",
        link: "/list-service",
        parentId: 23,
        permission: ["root", "list-services"],
      },
    ],
  },

  {
    id: 23,
    label: "MENUITEMS.PROVIDER.TEXT",
    permission: ["root", "create-provider", "list-providers"],

    icon: "fa-cloud",
    badge: {
      variant: "info",
      text: "MENUITEMS.PROVIDER.BADGE",
    },
    subItems: [
      {
        id: 24,
        label: "MENUITEMS.PROVIDER.LIST.PROVIDER",
        link: "/create/provider",
        parentId: 23,
        permission: ["root", "create-provider"],
      },
      {
        id: 24,
        label: "MENUITEMS.PROVIDER.LIST.LISTPROVIDER",
        link: "/list/provider",
        parentId: 23,
        permission: ["root", "list-providers"],
      },
    ],
  },

  {
    id: 23,
    label: "MENUITEMS.WHATSAPP.TEXT",
    permission: ["root", "whatsapp-service"],

    icon: "fa fa-address-book",
    badge: {
      variant: "info",
      text: "MENUITEMS.WHATSAPP.BADGE",
    },
    subItems: [
      {
        id: 24,
        label: "MENUITEMS.WHATSAPP.LIST.WHATSAPP",
        link: "/whatsapp-service",
        parentId: 23,
        permission: ["root", "whatsapp-service"],
      },
    ],
  },
  {
    id: 23,
    label: "MENUITEMS.REPORTING.TEXT",
    permission: ["root"],

    icon: "fa fa-envelope",
    badge: {
      variant: "info",
      text: "MENUITEMS.WHATSAPP.BADGE",
    },
    subItems: [
      {
        id: 24,
        label: "MENUITEMS.REPORTING.TEXT",
        link: "/reporting",
        parentId: 23,
        permission: ["root"],
      },
    ],
  },

  {
    id: 9,
    label: "MENUITEMS.ANALYTICS.TEXT",
    icon: "bx-calendar",
    link: "/analytics",
    permission: ["root"],
  },
  {
    id: 9,
    label: "MENUITEMS.SCHEDULING.TEXT",
    icon: "bx-calendar",
    link: "/scheduling",
    permission: ["root"],
  },
  {
    id: 28,
    label: "MENUITEMS.SHORTCODE.TEXT",
    permission: ["root", "create-shortcode", "list-shortcode"],

    icon: "fa-code",
    badge: {
      variant: "info",
      text: "MENUITEMS.SHORTCODE.BADGE",
    },
    subItems: [
      {
        id: 29,
        label: "MENUITEMS.SHORTCODE.LIST.SHORTCODE",
        link: "/create-shortcode",
        parentId: 28,
        permission: ["root", "create-shortcode"],
      },
      {
        id: 30,
        label: "MENUITEMS.SHORTCODE.LIST.LISTSHORTCODE",
        link: "/list-shortcode",
        parentId: 28,
        permission: ["root", "list-shortcode"],
      },
    ],
  },
  {
    id: 24,
    label: "MENUITEMS.SETTINGS.TEXT",
    permission: ["root", "email-template", "email-settings"],

    icon: "fa-wrench",
    badge: {
      variant: "info",
      text: "MENUITEMS.SETTINGS.BADGE",
    },
    subItems: [
      {
        id: 25,
        label: "MENUITEMS.SETTINGS.LIST.ETEMPLATE",
        link: "/email-template",
        parentId: 24,
        permission: ["root", "email-template"],
      },
      {
        id: 26,
        label: "MENUITEMS.SETTINGS.LIST.ESETTINGS",
        link: "/email-settings",
        parentId: 24,
        permission: ["root", "email-settings"],
      },
    ],
  },
  {
    id: 23,
    label: "Chat Flow",
    icon: "fa-comments",
    permission: ["root"],

    badge: {
      variant: "info",
      text: "MENUITEMS.SERVICEGROUP.BADGE",
    },
    subItems: [
      {
        id: 24,
        label: "List Chat Flows",
        link: "/list-flows",
        parentId: 23,
        permission: ["root"],
      },
    ],
  },
];
