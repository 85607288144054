<main [class.sidebar-toggle-btn-clicked]="http.sideBar">
  <div class="main-inner">
    <div class="main-content-box">
      <router-outlet></router-outlet>
      <ngx-spinner
        bdColor="rgba(0, 0, 0, 0.8)"
        size="medium"
        color="#18162F"
        type="square-jelly-box"
        [fullScreen]="true"
        ><p style="color: white">Loading...</p></ngx-spinner
      >
    </div>
  </div>
</main>
