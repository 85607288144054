import { Injectable } from "@angular/core";
import { HttpService } from "src/app/shared/services/http.service";
import { Router } from "@angular/router";
import { PermissionsService } from "./permissions.service";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class LogoutService {
  constructor(
    private http: HttpService,
    private router: Router,
    private permission: PermissionsService
  ) {}

  logout() {
    this.http.getRequest("/logout").subscribe(
      (response: any) => {
        if (response.success) {
          this.permission.user = null;
          this.permission.permissionGroups$ = [];
          localStorage.clear();
          this.router.navigate(["account/user/login"]);
        }
      },
      (error) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: error.error.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    );
  }
}
