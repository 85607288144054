import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import { environment } from "../environments/environment";
import {
  NgbNavModule,
  NgbAccordionModule,
  NgbTooltipModule,
  NgbModule,
} from "@ng-bootstrap/ng-bootstrap";
import { CarouselModule } from "ngx-owl-carousel-o";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";

import { ExtrapagesModule } from "./extrapages/extrapages.module";

import { LayoutsModule } from "./layouts/layouts.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { initFirebaseBackend } from "./authUtils";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { FakeBackendInterceptor } from "./core/helpers/fake-backend";
import { NgxPermissionsModule } from "ngx-permissions";
import { SocketioService } from "src/app/shared/services/socket.service";
import { NgxSpinnerModule } from "ngx-spinner";
import { SimpleModalModule } from "ngx-simple-modal";
// import { NodeService } from "./pages/dashboards/default/work-flow/child-node/node.service";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { StructuresModule } from "./structures/structures.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TreeviewModule } from "ngx-treeview";
import { NgxSelectModule } from "ngx-select-ex";
import { AuthGuard } from "./core/guards/auth.guard";
import { UserGuard } from "./core/guards/user.guard";
import { TokenInterceptorService } from "./core/services/token-interceptor.service";
import { MatTreeModule } from "@angular/material/tree";
import { RouterModule } from "@angular/router";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { NgxPaginationModule } from "ngx-pagination";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatMenuModule } from "@angular/material/menu";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ToastrModule } from "ngx-toastr";
if (environment.defaultauth === "firebase") {
  initFirebaseBackend(environment.firebaseConfig);
} else {
  // tslint:disable-next-line: no-unused-expression
  FakeBackendInterceptor;
}

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    LayoutsModule,
    NgxDatatableModule,
    AppRoutingModule,
    ExtrapagesModule,
    CarouselModule,
    NgxPermissionsModule.forRoot(),

    NgxSpinnerModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    ScrollToModule.forRoot(),
    SimpleModalModule.forRoot({ container: "modal-container" }),
    NgbModule,
    StructuresModule,
    FormsModule,
    TreeviewModule.forRoot(),
    NgxSelectModule,
    RouterModule,
    MatTreeModule,
    MatCheckboxModule,
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatInputModule,
    NgxPaginationModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    ToastrModule.forRoot(),
    MatProgressSpinnerModule,
    MatMenuModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  providers: [
    SocketioService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },

    AuthGuard,
    UserGuard,
    // NodeService,
  ],
  // providers: [
  //   { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  //   { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  //   { provide: HTTP_INTERCEPTORS, useClass: FakeBackendInterceptor, multi: true },
  // ],
})
export class AppModule {}
