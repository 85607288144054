export const environment = {
  SOCKET_ENDPOINT: "https://back.akoma.ai",
  serverUrl: "https://api.dev.siclytics.com/api/v2",

  production: true,
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  }
};
