<div class="sidebar">
  <div
    class="sidebar-taggle-btn"
    (click)="this.http.sideBar = !this.http.sideBar"
  >
    <i class="fa fa-angle-double-left" aria-hidden="true"></i>
  </div>
  <div class="logo-box text-center" >
    <img src="./assets/images/sic-logo.png" alt="logo" width="50%" />
  </div>
  <div class="nav-box">
    <nav>
      <ul>
        <li *ngFor="let item of links">
          <a
            [routerLink]="item?.url"
            routerLinkActive="active"
            (click)="removeUrl()"
            [routerLinkActiveOptions]="{
              exact:
                item?.url == '/' || item?.url == '/dashboard' ? true : false
            }"
            ><img [src]="item?.img" alt="img" /><span>{{ item?.name }}</span></a
          >
          <ng-container *ngIf="item.name == 'Dashboard'"> </ng-container>
        </li>
        <ng-container *ngIf="BillingReports.length">
          <li class="has-dropdown">
            <a
              [ngClass]="
                billingUrl == '/dashboard/billing-reports/organizational-list'
                  ? 'active'
                  : billingUrl == '/dashboard/billing-reports/provisional-list'
                  ? 'active'
                  : 'transparent'
              "
              href="javascript:void(0)"
              data-bs-toggle="collapse"
              data-bs-target="#billingReportsToggle"
              aria-expanded="false"
              aria-controls="billingReportsToggle"
              ><img
                src="./assets/images/svgIcons/billing-report.svg"
                alt="img"
              /><span>Billing Report</span></a
            >
            <ul
              class="dropdown-list1 accordion-collapse collapse"
              id="billingReportsToggle"
            >
              <li *ngFor="let item of BillingReports">
                <a
                  [routerLink]="item?.url"
                  routerLinkActive="active"
                  (click)="this.billingUrl = item?.url"
                  (click)="this.administratorUrl = null"
                  [class.paddingBottom]="BillingReports.length > 1"
                  >{{ item?.name }}</a
                >
              </li>
            </ul>
          </li>
        </ng-container>
        <ng-container *ngIf="permissionService.isServiceProvider()">
          <li class="has-dropdown">
            <a
              [ngClass]="
              cprReportUrl == '/dashboard/cpr-reports'
              ? 'active'
              : cprReportUrl == '/dashboard/add-report'
              ? 'active'
              : 'transparent'
              "
              href="javascript:void(0)"
              data-bs-toggle="collapse"
              data-bs-target="#reportsToggle"
              aria-expanded="false"
              aria-controls="reportsToggle"
              ><img
                src="./assets/images/svgIcons/billing-report.svg"
                alt="img"
              /><span>Report</span></a
            >
            <ul
              class="dropdown-list1 accordion-collapse collapse"
              id="reportsToggle"
            >
              <li *ngFor="let item of cprReportList">
                <a
                  [routerLink]="item?.url"
                  routerLinkActive="active"
                  (click)="this.cprReportUrl = item?.url"
                  (click)="this.administratorUrl = null"
                  [class.paddingBottom]="cprReportList.length > 1"
                  >{{ item?.name }}</a
                >
              </li>
            </ul>
          </li>
        </ng-container>

        <li *ngFor="let item of cdrUrl">
          <a
            (click)="removeUrl()"
            [routerLink]="item.url"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{
              exact:
                item?.url == '/' || item?.url == '/dashboard' ? true : false
            }"
            ><img [src]="item?.img" alt="img" /><span>{{ item?.name }}</span></a
          >
        </li>
        <ng-container *ngIf="administrationList.length">
          <li class="has-dropdown">
            <a
              [ngClass]="
              administratorUrl == '/dashboard/admin/roles'
                  ? 'active'
                  : administratorUrl == '/dashboard/admin/users'
                  ? 'active'
                  : administratorUrl == '/dashboard/admin/auditlogs'
                  ? 'active'
                  : 'transparent'
              "
              href="javascript:void(0)"
              data-bs-toggle="collapse"
              data-bs-target="#administrationToggle"
              aria-expanded="false"
              aria-controls="administrationToggle"
              ><img
                src="./assets/images/svgIcons/administration.svg"
                alt="img"
              /><span>Administration</span></a
            >
            <ul
              class="dropdown-list1 accordion-collapse collapse"
              id="administrationToggle"
            >
              <li *ngFor="let item of administrationList">
                <a
                  [routerLink]="item?.url"
                  routerLinkActive="active"
                  (click)="this.administratorUrl = item?.url"
                  (click)="this.billingUrl = null"
                  [class.paddingBottom]="BillingReports.length > 1"
                  >{{ item?.name }}</a
                >
              </li>
            </ul>
          </li>
        </ng-container>
        <ng-container *ngIf="profileList.length">
          <li class="has-dropdown">
            <a
              [ngClass]="
              profileUrl == '/dashboard/profile'
                  ? 'active'
                  : 'transparent'
              "
              href="javascript:void(0)"
              data-bs-toggle="collapse"
              data-bs-target="#profileToggle"
              aria-expanded="false"
              aria-controls="profileToggle"
              ><img
                src="./assets/images/svgIcons/User-Profile.svg"
                alt="img"
              /><span>Profile Settings</span></a
            >
            <ul
              class="dropdown-list1 accordion-collapse collapse"
              id="profileToggle"
            >
              <li *ngFor="let item of profileList">
                <a
                  [routerLink]="item?.url"
                  routerLinkActive="active"
                  (click)="this.profileUrl = item?.url"
                  (click)="this.billingUrl = null"
                  [class.paddingBottom]="BillingReports.length > 1"
                  >{{ item?.name }}</a
                >
                <a href="" (click)="onClickLogout()" style="cursor: hover;"> Log Out</a>
              </li>
            </ul>
          </li>
        </ng-container>


        <ng-container
          *ngIf="
            permissionService.isOrganization() &&
            permissionService.multipleOrganizations().length
          "
        >
          <div class="field-col">
            <select
              class="form-select"
              [(ngModel)]="selectedOrganizationID"
              (ngModelChange)="onChangeOrganization($event)"
            >
              <option value="" disabled selected hidden>
                Select organization
              </option>
              <option
                *ngFor="let org of permissionService.multipleOrganizations()"
                [value]="org.organization_id"
              >
                {{ org.company }}
              </option>
            </select>
          </div>
        </ng-container>
      </ul>
    </nav>
  </div>
</div>

<!-- Change Password Modal -->
<!-- The Modal -->
<div class="modal sic-modal fade" id="change-password">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title w-100 text-center ps-5">Change Password</h2>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
        ></button>
      </div>
      <div class="modal-body">
        <div class="px-5">
          <div class="inline-form-box">
            <div class="single-row">
              <div class="label-col">Enter Old Password</div>
              <div class="field-col">
                <input
                  [type]="show_button ? 'text' : 'password'"
                  class="form-control"
                  value=""
                  [(ngModel)]="old_Password"
                />
                <span class="cursor-pointer errspan" (click)="showPassword()">
                  <i
                    [class]="show_eye ? 'fa fa-eye' : 'fa fa-eye-slash'"
                    aria-hidden="true"
                  ></i>
                </span>
              </div>
            </div>
            <div class="single-row">
              <div class="label-col">New Password</div>
              <div class="field-col">
                <input
                  [type]="show_button1 ? 'text' : 'password'"
                  class="form-control"
                  value=""
                  [(ngModel)]="password"
                />
                <span class="cursor-pointer errspan" (click)="showPassword1()">
                  <i
                    [class]="show_eye1 ? 'fa fa-eye' : 'fa fa-eye-slash'"
                    aria-hidden="true"
                  ></i>
                </span>
              </div>
            </div>
            <div class="single-row">
              <div class="label-col">Confirm New Password</div>
              <div class="field-col">
                <input
                  [type]="show_button2 ? 'text' : 'password'"
                  class="form-control"
                  value=""
                  [(ngModel)]="Cpassword"
                />
                <span class="cursor-pointer errspan" (click)="showPassword2()">
                  <i
                    [class]="show_eye2 ? 'fa fa-eye' : 'fa fa-eye-slash'"
                    aria-hidden="true"
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="sic-btn sic-btn-secondary" (click)="changePassword()">
          Save
        </button>
        <!-- data-bs-dismiss="modal" -->
      </div>
    </div>
  </div>
</div>
