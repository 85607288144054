import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import Swal from "sweetalert2";
import { NgxPermissionsService } from "ngx-permissions";
import { PermissionsService } from "./permissions.service";

@Injectable({
  providedIn: "root",
})
export class UtilitiesService {
  UserDetail: any = null;
  checkIfUser1Info: boolean = false;
  currentUserDetail: any = null;

  constructor(
    private http: HttpService,
    private permissionsService: NgxPermissionsService,
    private permissionService: PermissionsService
  ) {
    if ("User1Info" in localStorage) {
      this.currentUserDetail = JSON.parse(localStorage.getItem("User1Info"));
      this.checkIfUser1Info = true;
    }
  }

  getUserDetailGlobal(id) {
    this.http.getRequest(`/get-user/${id}`).subscribe(
      (response: any) => {
        if (response.success) {
          this.UserDetail = response.data || {};
          if (response.data.avatar) {
            let imageUrl = `https://staging.siclytics.com/storage/images/${response.data.avatar}`;
            this.UserDetail.avatar = imageUrl;
          }
        }
      },
      (error) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: error.error.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    );
  }
  onClickBackImpersonate() {
    if (this.checkIfUser1Info) {
      this.currentUserDetail = JSON.parse(localStorage.getItem("User1Info"));
      localStorage.removeItem("User1Info");
      this.getUserDetailImpersonate(this.currentUserDetail.id, true);
    }
  }
  async getUserDetailImpersonate(id, bool: boolean) {
    await this.http.getRequest(`/impersonate/${id}`).subscribe(
      (response: any) => {
        console.log("Get user detail Impersonate", response);
        if (response.success) {
          const { data } = response || {};
          if (data) {
            if (!bool) {
              var currentUser = JSON.parse(localStorage.getItem("currentUser"));
              localStorage.setItem(
                "User1Info",
                JSON.stringify({
                  id: currentUser.user.id,
                  name: currentUser.user.name,
                })
              );
            }
            localStorage.setItem("currentUser", JSON.stringify(data));
            localStorage.setItem("token", JSON.stringify(data.token));

            this.permissionService.multipleOrganizations$ =
              data.user.other_organizations;
            data.user.user_roles.permissions.forEach((permissionsGroup) => {
              this.permissionService.permissionGroups$.push(
                permissionsGroup.name
              );
            });
            this.permissionService.permissionGroups$ = [
              ...new Set(this.permissionService.permissionGroups$),
            ];

            this.permissionService.multipleOrganizations$ = [
              ...new Set(this.permissionService.multipleOrganizations$),
            ];

            this.permissionsService.loadPermissions(
              this.permissionService.permissionGroups$
            );
            window.location.href = "dashboard";
          }
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: response.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      },
      (error) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: error.error.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    );
  }

  dateFormatYearMonthDate(str) {
    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = date.getDate(),
      year = date.getFullYear();
    return month + '/' + day + '/' + year;
  }
}
