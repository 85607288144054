import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "MENUITEMS.DASHBOARDS.TEXT",
    icon: "bx-home-circle",
    subItems: [
      {
        id: 2,
        label: "MENUITEMS.DASHBOARDS.LIST.DEFAULT",
        link: "/dashboard",
        parentId: 1,
      },
      {
        id: 3,
        label: "MENUITEMS.DASHBOARDS.LIST.SAAS",
        link: "/",
        parentId: 1,
      },
      {
        id: 4,
        label: "MENUITEMS.DASHBOARDS.LIST.CRYPTO",
        link: "/",
        parentId: 1,
      },
      {
        id: 5,
        label: "MENUITEMS.DASHBOARDS.LIST.BLOG",
        link: "/",
        parentId: 1,
      },
    ],
  },
];
