import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  sideBar: boolean = false;
  currentUser: any = null;

  constructor(private http: HttpClient) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  }
  serverUrl = environment.serverUrl;
  postDataWithSearchPagination(url, data) {
    return this.http.post(url, data);
  }

  postDataWithBaseURL(api, data) {
    return this.http.post(this.serverUrl + api, data);
  }
  postRequest(api, data) {
    return this.http.post(this.serverUrl + api, data);
  }
  patchReuest(api,data){
    return this.http.patch(this.serverUrl + api, data);
  }

  putRequest(api, data) {
    return this.http.put(this.serverUrl + api, data);
  }

  getRequest(api) {
    return this.http.get(this.serverUrl + api);
  }

  uploadFile(file: File) {
    const fd = new FormData();

    fd.append("uploadedImage", file);
    return this.http.post(this.serverUrl + "/uploadfile", fd, {
      reportProgress: true,
      observe: "events",
    });
  }
  deleteSingleItemRequest(api) {
    return this.http.delete(this.serverUrl + api);
  }
}
