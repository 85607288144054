import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NoDataFoundComponent } from "./no-data-found/no-data-found.component";
import { MatTreeModule } from "@angular/material/tree";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { HttpClientModule } from "@angular/common/http";
import { MatButtonModule } from "@angular/material/button";
@NgModule({
  declarations: [NoDataFoundComponent],
  imports: [
    CommonModule,
    MatTreeModule,
    MatCheckboxModule,
    MatIconModule,
    MatFormFieldModule,
    HttpClientModule,
    MatButtonModule,
  ],
  exports: [NoDataFoundComponent],
})
export class StructuresModule {}
