import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PermissionsService {
  user: any = null;
  permissionGroups$: Array<string> = [];
  MultiOrganizationName: string = "";
  multipleOrganizations$: Array<any> = [];
  constructor() {
    this.user = JSON.parse(localStorage.getItem("currentUser"));
    this.getClaims();
    this.OrganizationName();
  }

  loginUserDetail() {
    return !!JSON.parse(localStorage.getItem("currentUser"));
  }
  getToken() {
    return JSON.parse(localStorage.getItem("token"));
  }
  userId() {
    return this.user?.user ? this.user.user.id : null;
  }

  public getClaims() {
    var claims = JSON.parse(localStorage.getItem("currentUser"));
    var permissionArray: Array<any> = [];
    if (claims != null && claims != undefined) {
      claims.user.user_roles.permissions.forEach((permission) => {
        permissionArray.push(permission.name);
      });

      permissionArray = [...new Set(permissionArray)];
      return permissionArray;
    }
  }
  getCurrentUserDetail() {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser != null || currentUser != undefined)
      return currentUser.user;
  }

  isSuperAdmin(): boolean {
    if (this.getCurrentUserDetail()) {
      if (
        this.getCurrentUserDetail().service_provider_id == null &&
        this.getCurrentUserDetail().default_organization_id == null
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
  isServiceProvider(): boolean {
    if (this.getCurrentUserDetail()) {
      if (this.getCurrentUserDetail().service_provider_id != null) {
        return true;
      } else {
        return false;
      }
    }
  }
  isOrganization(): boolean {
    if (this.getCurrentUserDetail()) {
      if (this.getCurrentUserDetail().default_organization_id != null) {
        return true;
      } else {
        return false;
      }
    }
  }

  //Multi ORG Functions
  multipleOrganizations(): Array<any> {
    if (this.isOrganization()) {
      if (this.multipleOrganizations$.length) {
        return this.multipleOrganizations$;
      } else {
        this.multipleOrganizations$ =
          this.user?.user?.other_organizations ?? [];
      }
      return this.multipleOrganizations$;
    }
  }
  private OrganizationName() {
    this.MultiOrganizationName =
      this.user?.user?.default_organization?.company ?? "";
  }
}
