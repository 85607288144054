<!-- breadcrumb item -->
<!-- <div class="row">
  <div class="col-12">
    <div
      class="page-title-box d-flex align-items-center justify-content-between"
    >
      <h4 class="mb-0 font-size-18">{{ title }}</h4>
      <div class="page-title-right">
        <ol class="breadcrumb m-0">
          <ng-container *ngFor="let item of breadcrumbItems">
            <li class="breadcrumb-item" *ngIf="!item.active">
              <a href="javascript: void(0);">{{ item.label }}</a>
            </li>
            <li class="breadcrumb-item active" *ngIf="item.active">
              {{ item.label }}
            </li>
          </ng-container>
        </ol>
      </div>
    </div>
  </div>
</div> -->

<div class="main-breadcrumb">
  <div class="sic-breadcrumb">
    <nav
      style="
        --bs-breadcrumb-divider: url(
          &#34;data:image/svg + xml,
          %3Csvgxmlns='http://www.w3.org/2000/svg'width='8'height='8'%3E%3Cpathd='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z'fill='currentColor'/%3E%3C/svg%3E&#34;
        );
      "
      aria-label="breadcrumb"
    >
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item bold">
          <a href="#">{{ title }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          *ngFor="let item of breadcrumbItems"
        >
          {{ item.heading }}
        </li>
      </ol>
    </nav>
  </div>
</div>
