import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  private setLoader = new BehaviorSubject<any>(undefined);

  constructor() {}

  setMLoader(event) {
    this.setLoader.next(event);
  }

  getMLoader() {
    return this.setLoader;
  }
}
